import tattoo1 from './tattoos/tatto1.jpg'
import tattoo2 from './tattoos/tatto2.jpg'
import tattoo3 from './tattoos/tatto3.jpg'
import tattoo5 from './tattoos/tattoo5.jpg'
import tattoo6 from './tattoos/tattoo6.jpg'
import tattoo7 from './tattoos/tattoo7.jpg'
import tattoo8 from './tattoos/tattoo8.jpg'
import tattoo9 from './tattoos/tattoo9.jpg'
import tattoo10 from './tattoos/tattoo10.jpg'
import tattoo11 from './tattoos/tattoo11.jpg'
import tattoo12 from './tattoos/tattoo12.jpg'
// import tattoo13 from './tattoos/tattoo13.jpg'
import oni2 from './tattoos/oni2.jpg'
import tattoo14 from './tattoos/tattoo14.jpg'
import tattoo15 from './tattoos/tattoo15.jpg'
import origami from './tattoos/origami.jpg'
import blackDragon from './tattoos/blackdragon.jpg'
import octo from './tattoos/octo.jpg'
import tribal from './tattoos/tribal.jpg'
import blackkoi from './tattoos/blackkoi.jpg'
import jellyfish from './tattoos/jellyfish.jpg'

import frog from './tattoos/frog.jpg'
import frog2 from './tattoos/frog2.jpg'
import bird from './tattoos/bird.jpg'
import bird2 from './tattoos/bird2.jpg'
import paint from './tattoos/paint.jpg'
// import paint2 from './tattoos/paint2.jpg'
// import art from './tattoos/art4.jpg'
import koi from './tattoos/koi.jpg'

export const pictures = [
    {
        url: tattoo1,
        title: 'Iwanuma Kichirokurou Nobusato',
        subtitle: 'Extermination of monster newts',
        titleJapanese: '岩沼吉六郎信里',
        subtitleJapanese: '化けイモリ退治'
    },
    {
        url: tattoo2,
        title: 'Traditional Japanese Tattoo',
        // subtitle: 'subtitle of tattoo 2',
        titleJapanese: '日本伝統刺青',
        // subtitleJapanese: 'これは単なるテキスト例です'
    },
    {
        url: tattoo3,
        title: 'Traditional Japanese Tattoo Dragon 3/4 length',
        titleJapanese: '日本伝統刺青　龍　7分',
    },
    {
        url: tattoo14,
        title: 'Nine-tailed Fox (Front)',
        titleJapanese: '九尾の狐 (表側)',
    },
    // {
    //     url: tattoo4,
    //     title: 'Nine-tailed Fox (Back)',
    //     titleJapanese: '九尾の狐 (裏側)',
    // },
    {
        url: tattoo5,
        title: 'Koi and Cherry Blossoms',
        titleJapanese: '鯉と桜',
    },
    {
        url: frog,
        title: 'Raiya the Frog Riding on a Skull Exposed in the Field',
        // subtitle: 'subtitle of tattoo 6',
        titleJapanese: '野晒し髑髏の上に乗る蛙の児雷也',
        // subtitleJapanese: 'これは単なるテキスト例です'
    },
    {
        url: frog2,
        title: 'Frog father & son',
        subtitle: 'made for customer in celebration of his newborn daughter',
        titleJapanese: 'カエルの父と息子',
        subtitleJapanese: 'お客様の生まれたばかりの娘のお祝いに作られました'
    },
    {
        url: bird,
        title: 'White outline work: immediately after treatment',
        // subtitle: 'number 1 out of 2, healed',
        titleJapanese: '白筋　施術直後',
        // subtitleJapanese: '2 人中 1 位、癒されました'
    },
    {
        url: bird2,
        title: 'White outline work: healed',
        // subtitle: 'number 2 out of 2, fresh',
        titleJapanese: '白筋　治癒後',
        // subtitleJapanese: '2 人中 2 位、新鮮'
    },
    {
        url: paint,
        title: 'Princess Tamatori and the octopus',
        subtitle: 'Design for the entire back, watercolor painting',
        titleJapanese: '玉取姫と蛸',
        subtitleJapanese: '背中一面用図柄, 水彩画'
    },
    // {
    //     url: paint2,
    //     title: 'name of tattoo 11',
    //     subtitle: 'subtitle of tattoo 11',
    //     titleJapanese: 'これは見出しの一例です',
    //     subtitleJapanese: 'これは単なるテキスト例です'
    // },
    {
        url: jellyfish,
        title: 'Kaigetsu',
        subtitle: 'white outlines, fresh',
        titleJapanese: '海月',
        subtitleJapanese: '白筋　施術直後'
    },
    {
        url: koi,
        title: 'Nishikigoi',
        subtitle: 'Scales with white ink',
        titleJapanese: '錦鯉',
        subtitleJapanese: '鱗のみ白筋'
    },
    {
        url: tattoo6,
        title: 'Rising Koi & Sakura Cover-Up',
        titleJapanese: 'カバーアップ: 鯉の滝登りと桜',
    },
    {
        url: tattoo7,
        title: 'Guardian Dog & Japanese Maples',
        titleJapanese: '唐獅子と紅葉',
    },
    {
        url: tattoo8,
        title: 'Dragon and Tiger',
        subtitle: 'White streaks, black blur and vermilion',
        titleJapanese: '龍虎図',
        subtitleJapanese: '白筋　黒ぼかしと朱'
    },
    {
        url: tattoo9,
        title: 'Dakuniten',
        subtitle: 'water colored tattoo artwork',
        titleJapanese: '荼枳尼天',
        subtitleJapanese: '刺青用図柄水彩画'
    },
    {
        url: tattoo10,
        title: 'Fudo Myoo',
        subtitle: 'water colored backpiece tattoo artwork',
        titleJapanese: '不動明王',
        subtitleJapanese: '背中用図柄　水彩画'
    },
    {
        url: tattoo11,
        title: 'Yin & Yang',
        titleJapanese: '陰と陽',
    },
    {
        url: tattoo12,
        title: 'Kiryu Kannon',
        subtitle: 'Back design watercolor painting',
        titleJapanese: '騎龍観音',
        subtitleJapanese: '背中用図柄　水彩画'
    },
    {
        url: blackkoi,
        title: 'Black Koi & Lotus Blossoms',
        titleJapanese: '烏鯉と蓮',
    },
    {
        url: tattoo15,
        title: 'Octopus',
        subtitle: 'before cover-up and after treatment',
        titleJapanese: '蛸',
        subtitleJapanese: 'カバーアップ前と施術後'
    },
    {
        url: tribal,
        title: 'Black Tribal Design',
        titleJapanese: 'トライバル（黒）',
    },
    {
        url: octo,
        title: 'Octopus & Cherry Blossoms',
        titleJapanese: '蛸と桜',
    },
    {
        url: blackDragon,
        title: 'Black Dragon',
        subtitle: 'Full sleeve, black and gray only',
        titleJapanese: '黒龍',
        subtitleJapanese: '腕〜十分,黒ぼかしのみ'
    },
    {
        url: origami,
        title: 'Origami Crane & Sakura Flowers ',
        titleJapanese: '折り鶴と桜',
    },
    {
        url: oni2,
        title: 'Ashura',
        subtitle: 'God of Battle',
        titleJapanese: '阿修羅',
    },
    
]